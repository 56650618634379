.section-custom-hero-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
}

.section-custom-hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.section-custom-hero-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}